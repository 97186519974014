import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import "./ExerciseDateChart.css";

export default function ExerciseDateChart(props) {
  const [exerciseChartData, setExerciseChartData] = useState([]);

  
  useEffect(() => {
    const setupExerciseChartData = () => {
      function formatDate(currentDate) {
        const yyyy = currentDate.getFullYear();
        let mm = currentDate.getMonth() + 1;
        let dd = currentDate.getDate();
        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;
        return dd + "-" + mm + "-" + yyyy;
      }
      let data = {};
      props.exercises.map((exercise) => {
        const formattedDate = formatDate(new Date(exercise.date));
        if (Object.keys(data).includes(formattedDate))
          data[formattedDate] += exercise.amount;
        else data[formattedDate] = exercise.amount;
        return formattedDate;
      });
      return [
        [
          { type: "string", label: "Day" },
          ...Array.from(
            new Set(props.exercises.map((exercise) => exercise.name))
          ),
        ],
        ...Object.entries(data).sort().reverse()
      ];
    };
    if (props.exercises.length) {
      setExerciseChartData(setupExerciseChartData());
    }
  }, [props.exercises]);

  return (
    <div className="exercise-date-chart-component">
      <Chart
        className="exercise-date-chart"
        chartType="Line"
        width="80%"
        height="500px"
        data={exerciseChartData}
        options={{
          chart: {
            title: "Exercises by date",
          },
          series: {
            0: { axis: "Amount" },
          },
          axes: {
            y: {
              Temps: { label: "Amount" },
            },
          },
        }}
      />
    </div>
  );
}

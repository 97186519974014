import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

export default function ExerciseFocusAreaChart(props) {
  const [exerciseChartData, setExerciseChartData] = useState([]);

  useEffect(() => {
    if (props.exercises.length !== 0) {
      const data = props.exercises.reduce((acc, exercise) => {
        exercise.focusArea.map((_focusArea) => {
          if (acc[_focusArea]) {
            acc[_focusArea] += exercise.amount;
          } else {
            acc[_focusArea] = exercise.amount;
          }
          return _focusArea;
        });
        return acc;
      }, {});
      const dataArr = Object.entries(data);
      const chartData = [["Focus Area", "Amount"]].concat(dataArr);
      setExerciseChartData(chartData);
    }
  }, [props.exercises]);
  return (
    <div className="exercise-focus-area-chart-component">
      <Chart
        chartType="PieChart"
        data={exerciseChartData}
        options={{
          title: "Exercise Focus Areas",
          legend: "none",
        }}
        width={"100%"}
        height={"400px"}
      />
    </div>
  );
}

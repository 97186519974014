// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const apiKey = window?.env?.FIREBASE_API_KEY ?? process.env.FIREBASE_API_KEY;
const authDomain = window?.env?.FIREBASE_AUTH_DOMAIN ?? process.env.FIREBASE_AUTH_DOMAIN;
const projectId = window?.env?.FIREBASE_PROJECT_ID ?? process.env.FIREBASE_PROJECT_ID;
const storageBucket = window?.env?.FIREBASE_STORAGE_BUCKET ?? process.env.FIREBASE_STORAGE_BUCKET;
const messagingSenderId = window?.env?.FIREBASE_MESSAGING_SENDER_ID ?? process.env.FIREBASE_MESSAGING_SENDER_ID;
const appId = window?.env?.FIREBASE_APP_ID ?? process.env.FIREBASE_APP_ID;
const measurementId = window?.env?.FIREBASE_MEASUREMENT_ID ?? process.env.FIREBASE_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default app;
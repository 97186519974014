import { useEffect, useState } from "react";
import { Exercise } from "./models";
import "./App.css";
import { ExerciseDateChart, ExerciseFocusAreaChart } from "./components";

function App() {
  const [exercises, setExercises] = useState([]);

  const fetchExercises = async () => {
    const _exercises = await Exercise.getAll();
    setExercises(_exercises);
  };

  useEffect(() => {
    fetchExercises();
  }, []);

  return (
    <div className="App">
      <ExerciseDateChart exercises={exercises} />
      <ExerciseFocusAreaChart exercises={exercises} />
    </div>
  );
}

export default App;

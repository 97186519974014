import { firebase } from "../config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";

const FirestoreService = {
  addDocument: async (collectionName, data) => {
    try {
      const db = await getFirestore(firebase);
      await setDoc(doc(collection(db, collectionName)), data);
    } catch (err) {
      console.log(err);
    }
  },
  getDocumentById: async (collectionName, id) => {
    try {
      const db = getFirestore(firebase);
      const docRef = doc(collection(db, collectionName), id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  },
  getDocumentByField: async (collectionName, field, value) => {
    try {
      const db = getFirestore(firebase);
      const q = query(
        collection(db, collectionName),
        where(field, "==", value)
      );
      const querySnapshot = await getDocs(q);
      let docs = {};
      querySnapshot.forEach((doc) => {
        docs[doc.id] = doc.data();
      });
      return docs;
    } catch (err) {
      console.log(err);
    }
  },
  getAllDocuments: async (collectionName) => {
    try {
      const db = getFirestore(firebase);
      const querySnapshot = await getDocs(collection(db, collectionName));
      let docs = {};
      querySnapshot.forEach((doc) => {
        docs[doc.id] = doc.data();
      });
      return docs;
    } catch (err) {
      console.log(err);
    }
  },
  updateDocument: async (collectionName, id, data) => {
    try {
      const db = getFirestore(firebase);
      await setDoc(doc(collection(db, collectionName), id), data);
    } catch (err) {
      console.log(err);
    }
  },
};

export default FirestoreService;

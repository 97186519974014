import { ExerciseService } from '../services';

class Exercise {
  constructor(name, focusArea, amount, date) {
    this.name = name;
    this.focusArea = focusArea;
    this.amount = amount;
    this.date = date;
  }

  async create() {
    await ExerciseService.createExercise(this);
  }

  static async getById(id) {
    const _exercise = await ExerciseService.getExerciseById(id);
    return new Exercise(_exercise.name, _exercise.focusArea, _exercise.amount, _exercise.date);
  }

  static async getByName(name) {
    const exercises = await ExerciseService.getExerciseByName(name);
    return Object.values(exercises).map((exercise) => new Exercise(exercise.name, exercise.focusArea, exercise.amount, exercise.date));
  }

  static async getByDate(date) {
    const exercises = await ExerciseService.getExerciseByDate(date);
    return Object.values(exercises).map((exercise) => new Exercise(exercise.name, exercise.focusArea, exercise.amount, exercise.date));
  }

  static async getByFocusArea(focusArea) {
    const exercises = await ExerciseService.getExerciseByFocusArea(focusArea);
    return Object.values(exercises).map((exercise) => new Exercise(exercise.name, exercise.focusArea, exercise.amount, exercise.date));
  }

  static async getAll() {
    const exercises = await ExerciseService.getAllExercises();
    return Object.values(exercises).map((exercise) => new Exercise(exercise.name, exercise.focusArea, exercise.amount, exercise.date));
  }

  static async update(id, exercise) {
    await ExerciseService.updateExercise(id, exercise);
  }

  toJSON() {
    return {
      name: this.name,
      focusArea: this.focusArea,
      amount: this.amount,
      date: this.date
    };
  }
}

export default Exercise;
import FirestoreService from "./firestore.service";

const collection = "Exercise";

const ExercisesService = {
    createExercise: async (exercise) => {
        await FirestoreService.addDocument(collection, exercise.toJSON());
    },
    getExerciseById: async (id) => {
        return await FirestoreService.getDocumentById(collection, id);
    },
    getExerciseByName: async (name) => {
        return await FirestoreService.getDocumentByField(collection, "name", name);
    },
    getExerciseByDate: async (date) => {
        return await FirestoreService.getDocumentByField(collection, "date", date);
    },
    getExerciseByFocusArea: async (focusArea) => {
        return await FirestoreService.getDocumentByField(collection, "focusArea", focusArea);
    },
    getAllExercises: async () => {
        return await FirestoreService.getAllDocuments(collection);
    },
    updateExercise: async (id, exercise) => {
        await FirestoreService.updateDocument(collection, id, exercise.toJSON());
    }
};

export default ExercisesService;